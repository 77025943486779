import { styled } from '@lyfta/components-theme'

import { Box } from '../../../Components/Box'

export const Container = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  width: 100%;
`
