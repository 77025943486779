/* eslint-disable */
import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const Wrapper = styled(Box)``
export const Row = styled(Flex)`
  flex-direction: row;
`
export const Container = styled(Flex)`
  ${props =>
    props.error &&
    css`
      border: 2px solid ${themeGet('colors.error')};
    `};
  ${props =>
    !props.inputDiv &&
    css`
      position: relative;
      width: 20px;
      height: 20px;
      border: 2.5px solid ${themeGet('colors.black_700')};
      border-radius: 4px;
      user-select: none;

      ${props =>
        props.error &&
        css`
          border: 2px solid ${themeGet('colors.error')};
        `};

      > input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        margin: 0;
        width: 18px;
        height: 18px;
      }

      :before {
        cursor: pointer;
        opacity: 0;
        ${props =>
          props.checked &&
          css`
            opacity: 1;
          `};
        content: '';
        position: absolute;
        width: 11px;
        height: 6px;
        left: 3px;
        top: 3px;
        border-style: solid;
        border-width: 2px;
        border-bottom-color: ${themeGet('colors.black_700')};
        border-left-color: ${themeGet('colors.black_700')};
        border-top-color: ${themeGet('colors.clear')};
        border-right-color: ${themeGet('colors.clear')};
        transform: rotate(-60deg);
      }

      :hover:before {
        opacity: 0.1;
        ${props =>
          props.checked &&
          css`
            opacity: 1;
          `};
      }

      > input[type='checkbox']:checked {
        border: 2px solid #0e34a0;

        :after {
          opacity: 1;
          border: 2px solid #0e34a0;
          border-top: none;
          border-right: none;
        }
      }
    `};

  ${props =>
    props.inputDiv &&
    css`
      // Checkbox as div
      position: relative;
      align-items: center;
      font-weight: ${props => (props.checked ? '700' : '400')};
      min-height: 20px;
      cursor: pointer;
      padding-left: 30px;

      :before {
        content: '';
        position: absolute;

        width: ${props =>
          props.widthContainer ? props.widthContainer : '16px'};
        height: ${props =>
          props.heightContainer ? props.heightContainer : '16px'};
        left: ${props => (props.leftContainer ? props.leftContainer : '0')};
        top: ${props => (props.topContainer ? props.topContainer : '4px')};
        border-width: ${props => props.borderWidth || '2px'};
        border-style: ${props => props.borderStyle || 'solid'};
        border-color: ${props =>
          props.borderColor || themeGet('colors.black_700')(props)};
        border-radius: ${props =>
          props.borderRadius ? props.borderRadius : '4px'};
      }
      ${props =>
        !props.checked &&
        props.dirty &&
        css`
          :after {
            content: '';
            position: absolute;
            width: 8px;
            height: 1px;
            left: 4px;
            top: 11px;
            border-style: solid;
            border-width: 1px;
            border-bottom-color: ${themeGet('colors.black_700')};
          }
        `};

      ${props =>
        props.checked &&
        css`
          :after {
            content: '';
            position: absolute;
            width: ${props =>
              props.widthChecked ? props.widthChecked : '9px'};
            height: ${props =>
              props.heightChecked ? props.heightChecked : '4px'};
            left: ${props => (props.leftChecked ? props.leftChecked : '3.5px')};
            top: ${props => (props.topChecked ? props.topChecked : '9px')};
            border-style: solid;
            border-width: ${props => props.borderWidth || '2px'};
            border-color: ${themeGet('colors.black_700')};
            border-top: none;
            border-right: none;
            transform: rotate(-55deg);
          }
        `}
    `};

  ${props =>
    props.readOnly &&
    css`
      :before {
        border-color: ${themeGet('colors.lightGray')};
        cursor: default;
        color: ${themeGet('colors.lightGray')};
      }
    `};
`
export const Description = styled(Box).attrs(() => ({}))`
  font-weight: normal;
  color: ${themeGet('colors.font.accent')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`
