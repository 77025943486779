import { styled } from '@lyfta/components-theme'
import { Checkbox, TouchableFlex } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import * as React from 'react'

function SvgCheckBox({ checked, disabled, ...props }) {
  return (
    <TouchableFlex alignItems="center" disabled={disabled} {...props}>
      <Checkbox
        inputDiv
        topContainer="7px"
        leftContainer="-10px"
        topChecked="15px"
        leftChecked="-4.5px"
        heightChecked="5px"
        widthChecked="11px"
        checked={checked}
        widthContainer="22px"
        borderRadius="5px"
        heightContainer="22px"
        borderWidth="3px"
      />
    </TouchableFlex>
  )
}
SvgCheckBox.defaultProps = {
  disabled: false,
  outlineColor: '#000000',
  hoverColor: '#D8D8D8',
  style: {},
  onClick: null,
  onPress: null,
}
SvgCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  hoverColor: PropTypes.string,
  outlineColor: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onPress: PropTypes.func,
}
const StyledComponent = styled(SvgCheckBox)`
  ${({ disabled }) => {
    if (disabled) {
      return ''
    }
    return `
  svg:hover {
    #check {
      visibility: hidden;
    }
    #check-hover {
      visibility: visible;
    }
  }
`
  }}
`

StyledComponent.defaultProps = SvgCheckBox.defaultProps

export default StyledComponent
